import React, { useState, useEffect, useContext, useMemo } from "react";

import { getInicioMes, getFinalMes, getDecMes } from "../../../../services/date.services";

import FormatsUtils from "../../../../services/FormatsUtils";

import { PageControl, TabSheet, InputDate, InputDropDowList2, GradeOpcoes } from "../../../../components";

import { Button, Checkbox, Grid, IconButton, Typography } from "@material-ui/core";
import { AppContext } from "../../../../App";

import SearchIcon from "@material-ui/icons/Search";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import { AppOptionsContext } from "../../../../components/main/main";

import TablePagination from "../../../../components/grade/TablePagination";

import BalancesItemsUtils, { TypeStocksFilter } from "./utils";
import ApiBalancesItems from "../../../../api/stocks/balancesItems";
import Table from "../../../../components/grade/Table";
import ApiMovementsItems from "../../../../api/stocks/movementsItems ";
import { Alert } from "@material-ui/lab";
import formatUtils from "../../../../services/formatUtils";

import UpdateIcon from "@material-ui/icons/Update";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CancelIcon from "@material-ui/icons/Cancel";

import InputLookupMembers from "../../../basics/lookup/members";
import InputLookupItems from "../../../basics/lookup/items";

const initialCondition = {
    branch: {},
    item: {},
    hold_member: {},
    stock_keeper: TypeStocksFilter[0].value,
    initial_balance: getInicioMes(getDecMes()),
    final_balance: getFinalMes(getDecMes()),
};

const initialPending = {
    amount_pending: 0,
    movement_date_start: "",
    movement_date_end: "",
};

export default function BalancesItemsReport() {
    const { setLoading, showMessageError, showMessageInfo, showMessageConfirmation } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourcePending, setDataSourcePending] = useState(initialPending);
    const [closeDetail, setCloseDetail] = useState(true);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const metaData = useMemo(() => [
        { label: "Item", field: "item_id" },
        { label: "Nome do Item", field: "name" },
        {
            label: "Estoque",
            render: (record) => {
                return <span> {BalancesItemsUtils().typeStockText(record.stock_keeper)} </span>;
            },
        },
        { label: "Data Estoque", field: "start_date", format: "date" },
        { label: "Data Final", field: "end_date", format: "date" },
        {
            label: "Qtd.",
            field: "amount",
            format: "float",
            decimal: 3,
            align: "right",
        },
        {
            label: "Total",
            field: "total_balance",
            format: "float",
            decimal: 2,
            align: "right",
        },
        {
            label: "Posse/Proprietário",
            render: (record) => {
                return (
                    <>
                        {record.hold_member_name}
                        <br />
                        Documento: {FormatsUtils().document(record.hold_member_document, record.hold_member_type)}
                    </>
                );
            },
        },
    ]);

    const metaDataMovement = useMemo(() => [
        {
            label: "Opções",
            align: "center",
            render: (record) => {
                const options = [{ tipo: "cancel", label: "Cancelar Movimento", disabled: record.amount === 0 }];

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Data", field: "movement_date", format: "datetime" },
        {
            label: "Natureza Operação",
            render: (record) => {
                if (record.justify) {
                    return (
                        <>
                            {`${record.operation_nature_id}-${record.operation_nature_name}`}
                            <br />
                            {record.justify}
                        </>
                    );
                }

                return <>{`${record.operation_nature_id}-${record.operation_nature_name}`}</>;
            },
        },
        {
            label: "Qtd.",
            field: "amount",
            format: "float",
            decimal: 3,
            align: "right",
        },
        {
            label: "Total",
            render: (record) => {
                return <>{FormatsUtils().float(record.amount * record.cost_value, 2)}</>;
            },
        },
        {
            label: "Saldo?",
            align: "center",
            render: (record) => {
                return <Checkbox checked={record.status === "integrated"} />;
            },
        },
        { label: "Ordem Entrada", field: "inbound_order_id" },
        { label: "Ordem Saída", field: "outbound_order_id" },
        {
            label: "Documento",
            align: "right",
            render: (record) => {
                if (!record.document_number) {
                    return <></>;
                }

                return <>{`${record.document_number}-${record.document_serial}`}</>;
            },
        },
        { label: "Emissão Documento", field: "document_issue_date", format: "datetime" },
        { label: "Seq.Item", field: "document_item_seq", align: "right" },
    ]);

    useEffect(() => {
        setTitle("Listagem de Saldos e Movimentos");

        return () => {
            setOptions(null);
        };
    }, []);

    const onClickOptions = (record, type) => {
        switch (type) {
            case "cancel":
                showMessageConfirmation(
                    <>
                        Confirma cancelamento deste movimento de estoque? <br />
                        Movto: {record.operation_nature_name} <br />
                        Data: {formatUtils.formatDateTime(record.movement_date)} <br />
                        Quantidade: {FormatsUtils().float(record.amount, 3)} <br />
                        Este processo é irreversível.
                    </>,
                    () => {
                        onCancelMovementItem(record.id, record.movement_date);
                    }
                );
                break;
            default:
                break;
        }
    };

    const onCancelMovementItem = async (id, movementDate) => {
        setLoading(true);
        try {
            // List
            const result = await ApiMovementsItems().cancelMovement(id, movementDate);
            if (result.status) {
                showMessageInfo(["Registro cancelado!", "Aplique a pesquisa novamente."]);
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        // Required Fields
        const conditionValidate = newCondition || conditionApply;
        const message = [];

        if (!conditionValidate.branch?.id) {
            message.push(`Informe o estabelecimento.`);
        }

        if (!conditionValidate.initial_balance) {
            message.push(`Informe a data inicial para consulta.`);
        }

        if (!conditionValidate.final_balance) {
            message.push(`Informe a data final para consulta.`);
        }

        if (message.length) {
            showMessageError(message);
            return;
        }

        // Condition
        const conditionCustom = makeCondition(conditionValidate);

        setLoading(true);
        setCloseDetail(true);
        try {
            // List
            const result = await ApiBalancesItems().list({ page: newPage + 1, pageSize: newRowPage || rowPage, condition: conditionCustom });
            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                setDataSourcePending(initialPending);
                if (result.message) {
                    showMessageError(result.message);
                }
            }

            // Pending
            const resultPending = await ApiMovementsItems().listPending(conditionValidate.branch?.id);
            if (resultPending.status) {
                setDataSourcePending(resultPending.data);
            } else {
                setDataSourcePending(initialPending);
                if (resultPending.message) {
                    showMessageError(resultPending.message);
                }
            }
        } catch (e) {
            showMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
            setCloseDetail(false);
        }
    };

    const onRefreshBalancesItems = async () => {
        setLoading(true);
        try {
            // List
            const result = await ApiBalancesItems().refresh(conditionApply.branch.id);
            if (result.status) {
                showMessageInfo("Atualizações Efetuadas com Sucesso!");
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);

            // Apply
            loadDataSource(0, rowPage, condition);
            setPage(0);
            setConditionApply(condition);
        }
    };

    const makeCondition = (newCondition) => {
        const result = {
            branch_id: newCondition.branch?.id,
            initial_balance: FormatsUtils().dateAnsi(newCondition.initial_balance),
            final_balance: FormatsUtils().dateAnsi(newCondition.final_balance),
        };

        // Status
        if (newCondition.stock_keeper !== "-") {
            result.stock_keeper = newCondition.stock_keeper;
        }

        // Holder Member
        if (newCondition.hold_member?.id) {
            result.hold_member_id = newCondition.hold_member.id;
        }

        // Item
        if (newCondition.item?.id) {
            result.item_id = newCondition.item.id;
        }

        return result;
    };

    const renderDetails = (data, index) => {
        const dataSourceRecord = data.movements || [];

        return (
            <>
                <Typography variant="h6" gutterBottom component="div">
                    Movimentos
                </Typography>
                <Table dataSource={dataSourceRecord} metaData={metaDataMovement} />
            </>
        );
    };

    const onExportList = async (condition) => {
        setLoading(true);
        try {
            const conditionCustom = makeCondition(condition);

            const fileName = `Saldos-${condition.branch.document}-${conditionCustom.final_balance}`;
            const response = await ApiBalancesItems().listExport({ layout: "basic", format: "xlsx", fileName, condition: conditionCustom });

            if (!response.status) {
                showMessageError(response.message);
                return;
            }
        } finally {
            setLoading(false);
        }
    };

    const onExpandDetails = async (data, index) => {
        if (!data.movements) {
            const condition = {
                branch_id: data.branch_id,
                movement_date_more_equal: data.start_date,
                movement_date_less_equal: String(data.end_date).substring(0, 10) + " 23:59:59",
                item_id: data.item_id,
                stock_keeper: data.stock_keeper,
                type_stock: data.type_stock,
            };

            if (data.hold_member_id) {
                condition["hold_member_id"] = data.hold_member_id;
            } else {
                condition["hold_member_id_is_null"] = true;
            }

            setLoading(true);
            try {
                const result = await ApiMovementsItems().listDetails(condition);

                if (result.status) {
                    const dataSourceCustom = [...dataSource];
                    dataSourceCustom[index]["movements"] = result.data;

                    setDataSource(dataSourceCustom);
                } else {
                    setDataSource([]);
                    if (result.message) {
                        showMessageError(result.message);
                    }
                }
            } catch (e) {
                showMessageError(e.message);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Saldo de Itens">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputLookupMembers
                                roleMember="branch"
                                label="Estabelecimento"
                                value={condition.branch}
                                onChangeValue={(data) => setCondition({ ...condition, branch: data })}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data inicial"
                                value={condition.initial_balance}
                                onChangeValue={(date) => setCondition({ ...condition, initial_balance: date })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data final"
                                value={condition.final_balance}
                                onChangeValue={(date) => setCondition({ ...condition, final_balance: date })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList2
                                value={condition.stock_keeper}
                                onChangeValue={(e) => setCondition({ ...condition, stock_keeper: e.target.value })}
                                data={TypeStocksFilter}
                                label={"Estoque"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLookupItems
                                typeItem="goods"
                                label="Item"
                                value={condition.item}
                                onChangeValue={(data) => setCondition({ ...condition, item: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLookupMembers
                                roleMember="customer,supplier"
                                label="Posse/Proprietário"
                                value={condition.hold_member}
                                onChangeValue={(data) => setCondition({ ...condition, hold_member: data })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                loadDataSource(0, rowPage, condition);
                                setPage(0);
                                setConditionApply(condition);
                            }}
                            startIcon={<SearchIcon fontSize="inherit" />}
                        >
                            Pesquisar
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                setCondition(initialCondition);
                            }}
                            startIcon={<DeleteSweepIcon fontSize="inherit" />}
                        >
                            Limpar
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            variant="contained"
                            size="small"
                            color="secondary"
                            disabled={dataSourcePending.amount_pending === 0}
                            onClick={() => {
                                onRefreshBalancesItems();
                            }}
                            startIcon={<UpdateIcon fontSize="inherit" />}
                        >
                            Atualizar Lançamentos de Estoque
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            variant="contained"
                            size="small"
                            color="secondary"
                            disabled={dataSourcePending.amount_pending !== 0 || !dataSource.length}
                            onClick={() => {
                                onExportList(conditionApply);
                            }}
                            startIcon={<ReceiptIcon fontSize="inherit" />}
                        >
                            Exportar Relatório
                        </Button>
                    </Grid>
                    {dataSourcePending.amount_pending > 0 && (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Alert variant="filled" severity="error" size="small">
                                    Existem {dataSourcePending.amount_pending} lançamentos de estoque desatualizados no estabelecimento. Período entre{" "}
                                    {formatUtils.formatDateTime(dataSourcePending.movement_date_start)} e{" "}
                                    {formatUtils.formatDateTime(dataSourcePending.movement_date_end)}.
                                </Alert>
                            </Grid>
                        </Grid>
                    )}
                    <Table
                        dataSource={dataSource}
                        metaData={metaData}
                        details={true}
                        labelDetails="Movto"
                        renderDetails={renderDetails}
                        onExpandDetails={onExpandDetails}
                        closeDetails={closeDetail}
                    />
                </TabSheet>
            </PageControl>
        </>
    );
}
